import React from "react";
import {Chip, Fade, Grid, Paper, TextField, Tooltip} from "@mui/material";
import {VariantType, WithSnackbarProps} from "notistack";
import {IDBNode, IDBNodeLogItem, IDBUser, IDMSNode, Util} from "dms_commons";
import {Column, Query, QueryResult} from "@material-table/core";
import DMSNodesView from "./DMSNodesView";
import NewReleasesIcon from '@mui/icons-material/NewReleases';
import SignalCellularAltTwoToneIcon from "@mui/icons-material/SignalCellularAltTwoTone";
import SignalCellularConnectedNoInternet0BarTwoToneIcon
    from "@mui/icons-material/SignalCellularConnectedNoInternet0BarTwoTone";
import DMSUserView from "./DMSUserView";
import DMSPersistentTable from "./DMSPersistentTable";

interface IProps extends WithSnackbarProps {
    classes: any;
    persistFilters: boolean;
    dmsNodes?: Map<string, IDBNode>;
    dmsNodeLogQuery?: (query: Query<IDBNodeLogItem>) => Promise<QueryResult<IDBNodeLogItem>>;
    newLogEvents: number;
    currentUser?: IDBUser;
    logLine: (line: string) => void;
    displaySnackbar: (message: string, variant: VariantType) => void;
    dmsOnlineNodes: Map<string, IDMSNode>;
    lightMode?: boolean;
}

interface IState {
    logMenuAnchorElements: any[];
    dmsActivityLog?: IDBNodeLogItem[];
    dmsActivityLogColumns: Column<IDBNodeLogItem>[];
}

export default class DMSUserActivityView extends React.Component<IProps, IState> {
    static refreshedTimestamp = new Date();

    public state: IState = {
        logMenuAnchorElements: [],
        dmsActivityLogColumns: [],
    };

    private tableKey = "activity-table";

    public componentDidMount() {
        this.initDmsActivityLogColumns();
    }

    public componentWillUnmount() {
        DMSUserActivityView.refreshedTimestamp = new Date();
    }

    shouldComponentUpdate(nextProps: Readonly<IProps>, nextState: Readonly<IState>) {
        return nextProps.dmsNodes !== this.props.dmsNodes ||
            nextProps.newLogEvents !== this.props.newLogEvents ||
            JSON.stringify(nextState.dmsActivityLog) !== JSON.stringify(this.state.dmsActivityLog);
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
        if (this.state.dmsActivityLog && prevProps.dmsNodes?.size !== this.props.dmsNodes?.size) {
            return;
        }

        if (this.state.dmsActivityLog && this.props.dmsNodes === prevProps.dmsNodes && this.props.newLogEvents === prevProps.newLogEvents) {
            let shouldUpdate = false;

            this.props.dmsNodes?.forEach(value => {
                if (value["updated"]) {
                    shouldUpdate = true;
                    value["updated"] = false;
                }
            });

            if (!shouldUpdate) {
                return;
            }
        }

        let dmsActivityLog = new Array<IDBNodeLogItem>();

        for (const dmsNodesKey in this.props.dmsNodes) {
            const node = this.props.dmsNodes[dmsNodesKey] as IDBNode;

            if (node.lastLogEntry) {
                dmsActivityLog.push(node.lastLogEntry);
            }
        }

        dmsActivityLog.sort((a, b) => {
            const firestoreTimestampA = a.timestamp as {
                _seconds: number;
                _nanoseconds: number;
                seconds: number;
                nanoseconds: number;
            };

            const firestoreTimestampB = b.timestamp as {
                _seconds: number;
                _nanoseconds: number;
                seconds: number;
                nanoseconds: number;
            };

            return (firestoreTimestampB._seconds + (firestoreTimestampB._nanoseconds / 1000000000.0)) - (firestoreTimestampA._seconds + (firestoreTimestampA._nanoseconds / 1000000000.0));
        });

        if (this.props.lightMode) {
            dmsActivityLog = dmsActivityLog.slice(0, 25);
        }

        this.setState({dmsActivityLog}, () => {
            this.initDmsActivityLogColumns();
        });
    }

    private initDmsActivityLogColumns = () => {
        const {classes, dmsOnlineNodes, persistFilters} = this.props;

        const nodesFilter = {};
        const usersFilter = {};
        this.state.dmsActivityLog?.forEach(n => {
            if (!usersFilter[n.user]) {
                usersFilter[n.user] = n.user;
            }

            if (!nodesFilter[n.node!]) {
                nodesFilter[n.node!] = n.node;
            }
        });

        const dmsActivityLogColumns: Column<IDBNodeLogItem>[] = [
            {
                title: "Node",
                field: "node",
                align: "left",
                lookup: nodesFilter,
                filtering: true,
                width: 10,
                defaultFilter: persistFilters ? DMSPersistentTable.getDefaultFilter(this.tableKey, "node") : undefined,
                render: rowData => {
                    const isNewLog = (rowData.timestamp! as any)._seconds ? (rowData.timestamp! as any)._seconds * 1000 > DMSUserActivityView.refreshedTimestamp.getTime() : false;
                    const isNodeOnline = rowData.node !== undefined && dmsOnlineNodes && dmsOnlineNodes[rowData.node] !== undefined;

                    return <div className={classes.logItemNodeCell}>
                        <div style={{margin: "8px"}}><Fade
                            style={{display: isNodeOnline ? "block" : "none"}}
                            in={isNodeOnline}>
                            <SignalCellularAltTwoToneIcon aria-label={"online"} htmlColor="rgb(0,225,0)"/></Fade>
                            <Fade
                                style={{display: isNodeOnline ? "none" : "block"}}
                                in={!isNodeOnline}>
                                <SignalCellularConnectedNoInternet0BarTwoToneIcon aria-label={"offline"}
                                                                                  htmlColor={"orange"}/></Fade>
                        </div>
                        <p>{rowData.node}</p>
                        {isNewLog ? <Fade in={true} style={{margin: "8px"}}><NewReleasesIcon
                            style={{height: 20}}/></Fade> : undefined}
                    </div>;
                }
            },
            {
                title: "User",
                field: "user",
                align: "left",
                width: 1,
                filtering: true,
                lookup: usersFilter,
                defaultFilter: persistFilters ? DMSPersistentTable.getDefaultFilter(this.tableKey, "user") : undefined,
                render: rowData => {
                    return <DMSUserView classes={classes} username={rowData.user} isUserOnline={(username => {
                        return rowData.user === "dms_server" || (dmsOnlineNodes && dmsOnlineNodes[rowData.user] !== undefined);
                    })}/>;
                }
            },
            {
                title: "State",
                align: "left",
                width: 1,
                render: rowData => {
                    const nodeCurrentInstance = this.props.dmsNodes ? this.props.dmsNodes[rowData.node!] as IDBNode : undefined;

                    const lastLogEntry = nodeCurrentInstance?.lastLogEntry ?? rowData;

                    if (!lastLogEntry) {
                        return;
                    }

                    const nodeStateIcon = DMSNodesView.getNodeStateIcon(lastLogEntry.state);

                    return (<Chip
                        variant={"outlined"}
                        size={"small"}
                        icon={nodeStateIcon}
                        style={
                            {
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                            }
                        }
                        label={lastLogEntry.state}/>);
                }
            },
            {
                title: "Data",
                render: rowData => {
                    return (<TextField
                        variant={"standard"}
                        disabled={true}
                        value={rowData.data ?? ""}
                        className={classes.dialogTextField}
                        fullWidth
                        style={{
                            minWidth: 120
                        }}
                        multiline
                        InputProps={{
                            readOnly: true,
                        }}/>);
                }
            },
            {
                title: "Timestamp",
                field: "timestamp",
                type: "time",
                filtering: false,
                width: this.props.lightMode ? "20%" : undefined,
                align: "center",
                render: rowData => {
                    return <Tooltip title={Util.relativeDateTimeStringFromDBTimestamp(rowData.timestamp, true)}>
                        <p>{Util.relativeDateTimeStringFromDBTimestamp(rowData.timestamp)}</p>
                    </Tooltip>;
                },
                customSort: (a, b) => {
                    if (a.timestamp && b.timestamp) {
                        const firestoreTimestampA = a.timestamp as {
                            _seconds: number;
                            _nanoseconds: number;
                            seconds: number;
                            nanoseconds: number;
                        };

                        const firestoreTimestampB = b.timestamp as {
                            _seconds: number;
                            _nanoseconds: number;
                            seconds: number;
                            nanoseconds: number;
                        };

                        return (firestoreTimestampB._seconds + (firestoreTimestampB._nanoseconds / 1000000000.0)) - (firestoreTimestampA._seconds + (firestoreTimestampA._nanoseconds / 1000000000.0));
                    } else {
                        return 1;
                    }
                },
            },
        ];

        if (!this.props.lightMode) {
            dmsActivityLogColumns.forEach(c => {
                c.width = 1;
            });
        }

        this.setState({dmsActivityLogColumns}, () => {
            this.forceUpdate();
        });
    };

    private tableRef = React.createRef<any>();

    public render() {
        const {dmsNodeLogQuery} = this.props;
        const {dmsActivityLogColumns, dmsActivityLog} = this.state;

        return <Grid item xs={12}>
            <DMSPersistentTable
                components={{
                    Container: props => <Paper elevation={0} {...props} />
                }}
                emptyMessage={"No logs"}
                key={this.tableKey}
                tableKey={this.tableKey}
                tableRef={this.tableRef}
                title="Activity Log"
                columns={dmsActivityLogColumns}
                data={dmsNodeLogQuery ? dmsNodeLogQuery : dmsActivityLog ? dmsActivityLog : []}
                options={{
                    showTitle: !this.props.lightMode && true,
                    draggable: false,
                    pageSize: 25,
                    //paging: !this.props.lightMode,
                    header: !this.props.lightMode,
                    search: false,
                    pageSizeOptions: [25, 50, 100],
                    loadingType: "overlay",
                    filtering: !this.props.lightMode && true,
                    emptyRowsWhenPaging: false,
                    padding: this.props.lightMode ? "dense" : "default",
                    idSynonym: "timestamp"
                }}
                actions={[
                    {
                        icon: 'refresh',
                        tooltip: 'Refresh Data',
                        isFreeAction: true,
                        onClick: () => {
                            if (this.props.dmsNodeLogQuery && this.tableRef.current) {
                                this.tableRef.current.onQueryChange();
                            } else {
                                this.forceUpdate();
                            }
                        },
                    }
                ]}
            />
        </Grid>;
    }
}
